@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap);
body {
  margin: 0;
  font-family: "Roboto" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #f0f2f5;
}
a {
  text-decoration: none !important;
}
.bg-green{
  background-color: #2cd12c;
}
.ant-btn,
.ant-btn.btn-shadow {
  box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgb(0 0 0 / 25%);
  -webkit-box-shadow: 0 0 transparent, 0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
  -moz-box-shadow: 0 0 transparent, 0 0 transparent,
    0 1px 2px 0 rgb(0 0 0 / 25%);
  -ms-box-shadow: 0 0 transparent, 0 0 transparent, 0 1px 2px 0 rgb(0 0 0 / 25%);
}
#root {
  height: 100%;
}
.anticon svg {
  vertical-align: baseline;
}

.inline-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: rgba(142, 142, 142, 0.4);
}

.login-page .ant-form-item {
  margin-bottom: 11px !important;
}
.login-page .input-login {
  height: 50px;
}
.content-wrapper {
  padding-bottom: 50px;
}
.ant-form-item-with-help {
  margin-bottom: 5px;
}

.ant-table-thead.customize-header th {
  background-color: #333;
  color: #fff;
}

.receipt-wrapper {
  min-height: 480px;
}
.tab-name {
  cursor: pointer;
}
.text-login {
  color: #008efb;
}
.hr-login {
  background-color: #008efb;
  width: 70px;
}
.header-title {
  color: #fff !important;
  font-weight: bold;
}
.ant-layout-sider {
  background-color: #fff !important;
  box-shadow: 2px 2px 2px #ddd;
}
.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #333 !important;
  border-top: 1px solid #ddd;
}
.div-breadcrumb {
  background-color: #fff;
  /* height: 120px; */
  padding: 12px 24px;
  margin: -24px -24px 24px -24px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 999;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.p-breadcrumb {
  color: #888;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 15px;
}
.p-breadcrumb-title {
  color: #333;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
}
.span-breadcrumb-title {
  color: #888;
  font-size: 12px;
}
.selectedClub {
  background-color: #e1f2ff;
}
.ant-table-tbody > tr.selectedClub.ant-table-row:hover > td {
  background-color: #e1f2ff !important;
}
.leaflet-container {
  width: 100%;
  min-height: calc(100vh - 250px);
  height: 100%;
}
.leaflet-popup-content p {
  margin: 5px 0;
}
.icon-map-location {
  border-radius: 100%;
  border: 3px solid#315dee;
}
.icon-map-location.icon-map-selected {
  border: 3px solid #36f11e;
}
.text-forget {
  color: #008efb;
  cursor: pointer;
}
.map-container {
  top: 200px;
  width: 100%;
  height: 100%;
  left: 105px;
}
.text-red {
  color: red !important;
}
.pointer {
  cursor: pointer;
}
.inputnumber-onlyread .ant-input-number-input {
  font-weight: bold !important;
  text-align: right;
  padding: 0;
}
.div-item-comment {
  border-bottom: 1px solid #ddd;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
  word-break: break-all;
}
.div-item-comment:last-child {
  border-bottom: none;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
}
.comment-time {
  font-size: 12px;
}
.text-hidden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.footer {
  width: calc(100% + 48px);
  background-color: #fff;
  margin: 20px -24px 0 -24px !important;
  padding: 10px 24px;
  font-size: 12px;
}
.login-footer {
  width: 100%;
  background-color: #fff;
  margin: 0px !important;
  padding: 10px 24px;
  font-size: 12px;
  z-index: 99;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}
.dashboard-title {
  background-color: #fff;
  padding: 10px 24px;
  margin-bottom: 20px;
  font-size: 14px;
  width: -webkit-max-content;
  width: max-content;
}
.tabs-card {
  background-color: #fff;
  border: 1px solid #ddd;
}
.tabs-card .ant-tabs-tab {
  width: 120px;
}
.splide__arrow--next {
  right: -30px !important;
  border: 2px solid #333 !important;
  background: transparent !important;
}
.splide__arrow--prev {
  left: -30px !important;
  border: 2px solid #333 !important;
  background: transparent !important;
}
.splide__arrow svg {
  fill: #333 !important;
}

.text-video {
  position: absolute;
  bottom: 20px;
  left: 60px;
  color: red;
  background-color: #315dee;
}
.text-ellipsis-3-row {
    display: block;
  	display: -webkit-box;
  	line-height: 20px;
  	-webkit-line-clamp: 3;  /* số dòng hiển thị */
  	-webkit-box-orient: vertical;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	margin-top:10px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, 
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  border: 1px dashed #315dee;
  background: transparent !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before ,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before{
  border: 1px dashed #315dee;
  background: transparent !important ;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 0%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0%;
}

@media screen and (min-width: 1200px) {
  /* .map-container {
    position: fixed;
    width: 50%;
    left: 220px;
  } */
}
.upload-image-gift .ant-upload.ant-upload-select-picture-card{
  width: 100%;
  height: 300px;
}

.title-item-statustics{
  min-height: 48px;
  text-align: center;
}
.wrapper-item-statustics{
  border-radius: 10px;
  min-height: 156px;
}
.header-chart-statustics{
  padding: 10px 20px;
  color: white;
  font-size: 18px;
}
.border-radius-10{
  border-radius: 10px;
  overflow: hidden;
}
.report-member-wrapper{
  border-radius: 5px;
}
.report-member{
  background: #fff;
}
.report-member-title{
  font-size: 32px;
  text-align: center;
}
.report-member-total{
  display: flex;
  justify-content: center;
  padding: 0 32px;
}
.report-member-total__quantity{
  font-weight: 500;
  font-size: 50px;

}
.report-member-total__img{
  width: 96px;
  height: 96px;
  margin-right: 20px;
}
